var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{ref:"box"},[_c('div',{ref:"header"},[_c('a-page-header',{staticStyle:{"border-bottom":"1px solid rgb(235, 237, 240)"},attrs:{"title":"支付设置"}})],1),_c('a-form',{staticStyle:{"margin-top":"24px"},attrs:{"form":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"自动转账金额上限(元)："}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'extract_auto_price',
            {
              rules: [{ required: true, message: '请输入自动转账金额上限!' }],
            } ]),expression:"[\n            'extract_auto_price',\n            {\n              rules: [{ required: true, message: '请输入自动转账金额上限!' }],\n            },\n          ]"}],attrs:{"min":0,"precision":0,"step":"1"}})],1),_c('a-form-item',{attrs:{"label":"付款方名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'payer_show_name',
            {
              rules: [{ required: true, message: '请输入付款方名称!' }],
            } ]),expression:"[\n            'payer_show_name',\n            {\n              rules: [{ required: true, message: '请输入付款方名称!' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 14, offset: 4 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 保存 ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }